import {
  EqConfigModulePowerNumberTi,
  EqConfigModulePowerRatio,
  EqConfigModulePowerTiRange,
  EqConfigModulePowerType
} from './';
import {
  EqConfigInstallationInformationCompressorType
} from './eq-config-installation-information-compressor-type.enum';
import { EqConfigInstallationInformationCondeserType } from './eq-config-installation-information-condeser-type.enum';
import { EqConfigInstallationInformationTankType } from './eq-config-installation-information-tank-type.enum';

//import { EquipmentConfigGeneralStatistic } from './equipment-config-general-statistics.enum';

export class EquipmentConfigUpdate {
  public _id: string;
  public equipment: string;
  public measure: any;
  // Alarms
  public general_low_level_limit: number;
  public general_low_level_delay: number;
  public general_low_level_limit_pumpdown: number;
  public general_low_level_delay_pumpdown: number;
  public general_statistic_3days_limit: number;
  public pumpdown_status:boolean;

  // Configuration
  public installation_information_fluid_charge: number;
  public installation_information_fluid_type: string;
  public teq_CO2: string;
  public installation_information_tank_type: EqConfigInstallationInformationTankType;
  public installation_information_tank_tilt: number;
  public installation_information_tank_number: number;
  public installation_information_tank_volume: number;
  public installation_information_tank_height: number;
  public installation_information_tank_diameter: number;
  public flags_cooler: boolean;
  public installation_information_condenser_volume: number;
  public installation_information_hp_consign: number;
  public flags_floating_hp: boolean;
  public flags_floating_lp: boolean;
  public installation_information_lp_consign: number;
  public installation_information_compressor_type: EqConfigInstallationInformationCompressorType;
  public installation_information_condeser_type: EqConfigInstallationInformationCondeserType;
  public installation_information_condenser_power: number;

  // Measure
  public measure_configuration_hp_min: number;
  public measure_configuration_hp_max: number;
  public installation_information_column_type: string;

  // Energy Module
  public energy_voltage_recording: number;
  public energy_voltage_recalculation: boolean;
  // Module 1
  public module1_power1_type: EqConfigModulePowerType;
  public module1_power2_type: EqConfigModulePowerType;
  public module1_power3_type: EqConfigModulePowerType;
  public module1_power1_ti_range: EqConfigModulePowerTiRange;
  public module1_power2_ti_range: EqConfigModulePowerTiRange;
  public module1_power3_ti_range: EqConfigModulePowerTiRange;
  public module1_power1_number_ti: EqConfigModulePowerNumberTi;
  public module1_power2_number_ti: EqConfigModulePowerNumberTi;
  public module1_power3_number_ti: EqConfigModulePowerNumberTi;
  public module1_power1_power_ratio: EqConfigModulePowerRatio;
  public module1_power2_power_ratio: EqConfigModulePowerRatio;
  public module1_power3_power_ratio: EqConfigModulePowerRatio;
  public measure_configuration_lp_min: number;
  public measure_configuration_lp_max: number;
  // Module 2
  public module2_power1_type: EqConfigModulePowerType;
  public module2_power2_type: EqConfigModulePowerType;
  public module2_power3_type: EqConfigModulePowerType;
  public module2_power1_ti_range: EqConfigModulePowerTiRange;
  public module2_power2_ti_range: EqConfigModulePowerTiRange;
  public module2_power3_ti_range: EqConfigModulePowerTiRange;
  public module2_power1_number_ti: EqConfigModulePowerNumberTi;
  public module2_power2_number_ti: EqConfigModulePowerNumberTi;
  public module2_power3_number_ti: EqConfigModulePowerNumberTi;
  public module2_power1_power_ratio: EqConfigModulePowerRatio;
  public module2_power2_power_ratio: EqConfigModulePowerRatio;
  public module2_power3_power_ratio: EqConfigModulePowerRatio;
  public measure_configuration_hp_gas_min: number;
  public measure_configuration_hp_gas_max: number;
  // Module 3
  public module3_power1_type: EqConfigModulePowerType;
  public module3_power2_type: EqConfigModulePowerType;
  public module3_power3_type: EqConfigModulePowerType;
  public module3_power1_ti_range: EqConfigModulePowerTiRange;
  public module3_power2_ti_range: EqConfigModulePowerTiRange;
  public module3_power3_ti_range: EqConfigModulePowerTiRange;
  public module3_power1_number_ti: EqConfigModulePowerNumberTi;
  public module3_power2_number_ti: EqConfigModulePowerNumberTi;
  public module3_power3_number_ti: EqConfigModulePowerNumberTi;
  public module3_power1_power_ratio: EqConfigModulePowerRatio;
  public module3_power2_power_ratio: EqConfigModulePowerRatio;
  public module3_power3_power_ratio: EqConfigModulePowerRatio;
}
