import { BaseModel } from './base.model';
import { InstallerChain } from './installer-chain';

export class Installer extends BaseModel {
  public phone: string;
  public name: string;
  public chain: InstallerChain;
  public logo: string;

  constructor(valuesObj: any) {
    super(valuesObj);
    if (valuesObj.chain) {
      this.chain = new InstallerChain(valuesObj.chain);
    }
  }
}
