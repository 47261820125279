export abstract class BaseModel {
  public _id: string;
  [propertyName: string]: any;

  constructor(valuesObj: any) {
    Object.assign(this, valuesObj);
  }

  public patchData(valuesObj: any) {
    Object.assign(this, valuesObj);
  }
}
