import { BaseModel } from '@shared/models/base.model';

import { Role } from './role';
import { UserRole } from './user-role';
import { CustomerLocation } from '@shared/models/customer-location';
import { CustomerChain } from '@shared/models/customer-location-chain';
import { Installer } from '@shared/models/installer';
import { InstallerChain } from '@shared/models/installer-chain';

export class User extends BaseModel {
  public _id: string;
  public email: string;
  public name: string;
  public firstName: string;
  public lastName: string;
  public phone: string;
  public password: string;
  public active: boolean;
  public blocked: boolean;
  public roles: Role[];
  public userRolesForEquipment: UserRole[];
  public userRoles: UserRole[];
  public profileImage: string;
  public createdOn: Date;
  public lastConnection: string;
  public canBeRead: boolean;
  public canBeEdited: boolean;
  public customerLocationAlarmsDisabled: string[];
  public useFilteredAlarms: boolean;

  constructor(valuesObj: User) {
    super(valuesObj);

    if (valuesObj.userRolesForEquipment) {
      this.userRolesForEquipment = valuesObj.userRolesForEquipment
        .map((userRole: UserRole) => new UserRole(userRole))
        .sort(this.sortByCompanyLevel);
    } else if (valuesObj.userRoles) {
      this.userRoles = valuesObj.userRoles.map((userRole: UserRole) => new UserRole(userRole)).sort(this.sortByCompanyLevel);
    }

    if (valuesObj.role) {
      this.role = new Role(valuesObj.role);
    }
  }

  public get companyName(): string {
    const userRole = (this.userRolesForEquipment || this.userRoles || [])[0];

    return userRole?.customerChain?.name || userRole?.customerLocation?.name || userRole?.installerChain?.name || userRole?.installer?.name;
  }

  public get roleName(): string {
    const userRole = (this.userRolesForEquipment || this.userRoles || [])[0];
    return userRole?.role?.name;
  }

  public getRolesNames(): string[] {
    if (!Array.isArray(this.roles)) {
      return [];
    }

    return this.roles.map((role: Role) => {
      return role.name;
    });
  }

  public formatRoleToUserRoles(): UserRole[]{
    return this.roles.map((role: Role) => {
      const userRole: UserRole = new UserRole({} as any);

      userRole.role = role._id as any;

      if (role.userRole.customerLocation && (role.userRole.customerLocation as CustomerLocation)._id) {
        userRole.customerLocation = (role.userRole.customerLocation as CustomerLocation)._id as any;
      }
      if (role.userRole.customerChain && (role.userRole.customerChain as CustomerChain)._id) {
        userRole.customerChain = (role.userRole.customerChain as CustomerChain)._id as any;
      }
      if (role.userRole.installer && (role.userRole.installer as Installer)._id) {
        userRole.installer = (role.userRole.installer as Installer)._id as any;
      }
      if (role.userRole.installerChain && (role.userRole.installerChain as InstallerChain)._id) {
        userRole.installerChain = (role.userRole.installerChain as InstallerChain)._id as any;
      }

      return userRole;
    });
  }

  public updateCustomerLocationAlarmsDisabled(isAlarmsDisabled: boolean, customerLocationId: string): void{
    const indexOfCustomerLocation = this.customerLocationAlarmsDisabled.indexOf(customerLocationId);

    if (isAlarmsDisabled){
      if (indexOfCustomerLocation === -1){
        this.customerLocationAlarmsDisabled.push(customerLocationId);
      }
    }else{
      if (indexOfCustomerLocation !== -1){
        this.customerLocationAlarmsDisabled.splice(indexOfCustomerLocation, 1);
      }
    }
  }
  public isDisabledCustomerLocationAlarms(customerLocationId: string): boolean{
    return !(this.useFilteredAlarms && !(this.customerLocationAlarmsDisabled?.includes(customerLocationId)??false));
  }
}
