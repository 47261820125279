import { BaseModel } from '@shared/models/base.model';
import { CustomerLocation } from '@shared/models/customer-location';
import { CustomerChain } from '@shared/models/customer-location-chain';
import { Installer } from '@shared/models/installer';
import { InstallerChain } from '@shared/models/installer-chain';

import { Role } from './role';
import { UserRoleSpecialAlarmType } from '@users/domain/models/user-role-special-alarm-type';

export class UserRole extends BaseModel {
  public _id: string;
  public user: string;
  public role: Role;
  public customerChain?: CustomerChain;
  public customerLocation?: CustomerLocation;
  public installerChain?: InstallerChain;
  public installer?: Installer;
  public dateCreated: Date;

  constructor(valuesObj: UserRole) {
    super(valuesObj);

    if (valuesObj.role) {
      this.role = new Role(valuesObj.role);
    }
  }

  public getCompanyName(): string {
    if (this.customerLocation) {
      return this.customerLocation.name;
    } else if (this.customerChain) {
      return this.customerChain.name;
    } else if (this.installer) {
      return this.installer.name;
    } else if (this.installerChain) {
      return this.installerChain.name;
    }
    return 'unknown';
  }
}
