import { BaseMultilanguage } from './base-multilanguage.model';
import { AlarmType } from './alarm-type';
import { Equipment } from './equipment';

export class Alarm extends BaseMultilanguage {
  public alarm_type: AlarmType;
  public target_id: Equipment;

  constructor(valuesObj: any) {
    super(valuesObj);
    if (valuesObj.alarm_type) {
      this.alarm_type = new AlarmType(valuesObj.alarm_type);
    }
  }
}
