import { BaseMultilanguage } from './base-multilanguage.model';

export class EquipmentStatus extends BaseMultilanguage {
  public id: string;
  public status: string;
  public icon: string;
  public color: string;
  public colorDistanceFromRed: number;
  public backgroundColor: string;
}
