import { BaseMultilanguage } from './base-multilanguage.model';

export class AlarmType extends BaseMultilanguage {
  public id: string;
  public name: string;
  public color: string;
  public backgroundColor: string;
  public icon: string;
  public dateCreated: Date;
  public dateUpdated: Date;
}
