import { BaseModel } from './base.model';

export class BaseMultilanguage extends BaseModel {
  public mlVal(propName: string, lang: string) {
    if (this.translations && Array.isArray(this.translations)) {
      for (const translation of this.translations) {
        if (translation.locale !== lang) {
          continue;
        }
        if (translation.fields && Array.isArray(translation.fields)) {
          for (const field of translation.fields) {
            if (field.field !== propName) {
              continue;
            }
            return field.value;
          }
        }
      }
    }
  }
}
