<div class="matelex-button">
  <button class="{{bordered ? 'bordered-basic' : ''}}"
          [style]="{'color': customColor !== '' ? customColor : ''}"
          [disabled]='disabled' mat-stroked-button>
    <ng-container *ngIf="!loading">
      <mat-icon fontSet="material-icons-outlined" class='float-start me-2'>
        {{icon}}
      </mat-icon>
      <span>{{label}}</span>
    </ng-container>
    <div class="loading-container">
      <ng-container *ngIf="loading">
        <mat-spinner diameter="30"></mat-spinner>
      </ng-container>
    </div>
  </button>
</div>
