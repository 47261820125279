import { Fluid } from '@shared/models/fluid';
import { BaseModel } from 'src/app/shared/models/base.model';
import { Equipment } from 'src/app/shared/models/equipment';

import {
  EqConfigEnergyIsConfigured,
  EqConfigInstallationInformationCompressorType,
  EqConfigModulePowerNumberTi,
  EqConfigModulePowerRatio,
  EqConfigModulePowerTiRange,
  EqConfigModulePowerType,
  EqConfigNetworkConnectionType,
  EqConfigNetworkDhcp
} from './';
import { EqConfigInstallationInformationCondeserType } from './eq-config-installation-information-condeser-type.enum';
import { EqConfigInstallationInformationTankType } from './eq-config-installation-information-tank-type.enum';

//import { EquipmentConfigGeneralStatistic } from './equipment-config-general-statistics.enum';

export class EquipmentConfig extends BaseModel {
  public _id: string;
  public equipment: Equipment;

  // Alarms
  public general_low_level_limit: number;
  public general_low_level_delay: number;
  public general_low_level_limit_pumpdown: number;
  public general_low_level_delay_pumpdown: number;
  public general_statistic_3days_limit: number;
  public measure: any;
  // Configuration
  public installation_information_fluid_charge: number;
  public installation_information_fluid_type: Fluid;
  public readonly teq_CO2: string;
  public installation_information_tank_type: EqConfigInstallationInformationTankType;
  public installation_information_tank_tilt: number;
  public installation_information_tank_number: number;
  public installation_information_tank_volume: number;
  public installation_information_tank_height: number;
  public installation_information_tank_diameter: number;
  public flags_cooler: boolean;
  public installation_information_condenser_volume: number;
  public installation_information_hp_consign: number;
  public flags_floating_hp: boolean;
  public flags_floating_lp: boolean;
  public installation_information_lp_consign: number;
  public installation_information_compressor_type: EqConfigInstallationInformationCompressorType;
  public installation_information_condeser_type: EqConfigInstallationInformationCondeserType;
  public installation_information_condenser_power: number;
  public confirm_repaired_leaks : boolean;
  // Measure
  public measure_configuration_hp_min: number;
  public measure_configuration_hp_max: number;
  public readonly measure_configuration_hp_calibration: number;
  public readonly external_temp_correction_auto: number;
  public readonly external_temp_correction: number;
  public readonly measure_configuration_input_temp_correction_auto: number;
  public readonly measure_configuration_input_temp_correction: number;
  public readonly measure_configuration_output_temp_correction_auto: number;
  public readonly measure_configuration_output_temp_correction: number;
  public installation_information_column_type: string;
  public readonly installation_information_column_tare: string;
  public readonly measure_card_1_is_configured: boolean;

  // Energy Module
  public readonly energy1_is_configured: EqConfigEnergyIsConfigured;
  public readonly energy2_is_configured: EqConfigEnergyIsConfigured;
  public readonly energy3_is_configured: EqConfigEnergyIsConfigured;
  public readonly energy_voltage_reference: number;
  public energy_voltage_recording: number;
  public energy_voltage_recalculation: boolean;
  // Module 1
  public module1_power1_type: EqConfigModulePowerType;
  public module1_power2_type: EqConfigModulePowerType;
  public module1_power3_type: EqConfigModulePowerType;
  public module1_power1_ti_range: EqConfigModulePowerTiRange;
  public module1_power2_ti_range: EqConfigModulePowerTiRange;
  public module1_power3_ti_range: EqConfigModulePowerTiRange;
  public module1_power1_number_ti: EqConfigModulePowerNumberTi;
  public module1_power2_number_ti: EqConfigModulePowerNumberTi;
  public module1_power3_number_ti: EqConfigModulePowerNumberTi;
  public module1_power1_power_ratio: EqConfigModulePowerRatio;
  public module1_power2_power_ratio: EqConfigModulePowerRatio;
  public module1_power3_power_ratio: EqConfigModulePowerRatio;
  public readonly measure_configuration_lp_calibration: number;
  public measure_configuration_lp_min: number;
  public measure_configuration_lp_max: number;
  public readonly measure_configuration_suction_temp_correction_auto: number;
  public readonly measure_configuration_suction_temp_correction: number;
  // Module 2
  public module2_power1_type: EqConfigModulePowerType;
  public module2_power2_type: EqConfigModulePowerType;
  public module2_power3_type: EqConfigModulePowerType;
  public module2_power1_ti_range: EqConfigModulePowerTiRange;
  public module2_power2_ti_range: EqConfigModulePowerTiRange;
  public module2_power3_ti_range: EqConfigModulePowerTiRange;
  public module2_power1_number_ti: EqConfigModulePowerNumberTi;
  public module2_power2_number_ti: EqConfigModulePowerNumberTi;
  public module2_power3_number_ti: EqConfigModulePowerNumberTi;
  public module2_power1_power_ratio: EqConfigModulePowerRatio;
  public module2_power2_power_ratio: EqConfigModulePowerRatio;
  public module2_power3_power_ratio: EqConfigModulePowerRatio;
  public readonly measure_configuration_hp_gas_calibration: number;
  public measure_configuration_hp_gas_min: number;
  public measure_configuration_hp_gas_max: number;
  public readonly measure_configuration_discharge_temp_correction_auto: number;
  public readonly measure_configuration_discharge_temp_correction: number;
  // Module 3
  public module3_power1_type: EqConfigModulePowerType;
  public module3_power2_type: EqConfigModulePowerType;
  public module3_power3_type: EqConfigModulePowerType;
  public module3_power1_ti_range: EqConfigModulePowerTiRange;
  public module3_power2_ti_range: EqConfigModulePowerTiRange;
  public module3_power3_ti_range: EqConfigModulePowerTiRange;
  public module3_power1_number_ti: EqConfigModulePowerNumberTi;
  public module3_power2_number_ti: EqConfigModulePowerNumberTi;
  public module3_power3_number_ti: EqConfigModulePowerNumberTi;
  public module3_power1_power_ratio: EqConfigModulePowerRatio;
  public module3_power2_power_ratio: EqConfigModulePowerRatio;
  public module3_power3_power_ratio: EqConfigModulePowerRatio;
  // Network info
  public readonly mac_address_external: string;
  public readonly network_connection_type: EqConfigNetworkConnectionType;
  public readonly network_wifi_ssid: string;
  public readonly network_wifi_key: string;
  public readonly network_wifi_type: string;
  public readonly network_dhcp: EqConfigNetworkDhcp;
  public readonly network_gateway: string;
  public readonly network_mask: string;
  public readonly network_ip_address: string;
  public readonly network_vpn_ip_address: string;

  public constructor(valuesObj: any) {
    super(valuesObj);
  }
}
