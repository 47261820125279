import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AppInjector } from '../../app.injector';
import { Permission } from '../../users/domain/services/user.enums';
import { UserInfo } from '../../users/domain/services/user.interfaces';
import { UserService } from '../../users/domain/services/user.service';
import { JwtHelper } from './jwt.Helper';
import { LanguageService } from './language.service';

@Injectable()
export class TokenSessionService {
  private readonly bufferTimeMin: number = 5;
  private readonly bufferTimeMs: number = this.bufferTimeMin * 60 * 1000;
  private warningTimeout: any;
  private logoutTimeout: any;

  public get userService(): UserService {
    return AppInjector.getInjector().get(UserService);
  }
  public get userInfo(): UserInfo {
    return this.jwtHelper.decodeToken(this.getToken() as string);
  }

  public get permissions(): Permission[] {
    return this.userInfo ? this.userInfo.permissions : [];
  }

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private languageService: LanguageService,
    private jwtHelper: JwtHelper
  ) {}

  public tokenExpiredLogout(): boolean{
    if (!this.isAuthenticated()){
      this.logout();
      return true;
    }
    return false;
  }
  public handleToken(): void {
    clearTimeout(this.warningTimeout);
    clearTimeout(this.logoutTimeout);
    if (!this.getToken()) {
      return;
    }
    if (this.getTokenTimeout() <= this.bufferTimeMs) {
      this.logout();
    } else {
      this.warningTimeout = setTimeout(() => {}, this.getTokenTimeout() - this.bufferTimeMs);
    }
  }

  public getTokenTimeout(): number {
    return this.jwtHelper.getTokenTimeout(this.getToken() as string);
  }

  public logout(): void {
    this.logoutUser();
    this.userService.loginStatusUpdates.next({
      userInfo: this.userInfo,
      token: this.getToken()
    });
    this.router.navigate([`/${this.languageService.currentLanguage}/users/login`]);
  }

  public logoutUser(): void {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  }

  public isAuthenticated(): boolean {
    return !!this.getToken() && this.getTokenTimeout() > 0;
  }

  // private addEventListenerForWarningBanner(): void {
  //   setTimeout(() => {
  //     document.getElementById(this.refreshTokenElementSelector).addEventListener('click', async () => {
  //       await this.userService.updateToken(this.profileService.userInfo);
  //       this.handleToken();
  //     });
  //   });
  // }

  private get logoutBannerMsg(): string {
    return this.translateService.instant('BANNER.SESSION_EXPIRED');
  }

  private get warningBannerMsg(): string {
    return this.translateService.instant('BANNER.WARNING_EXPIRE', {
      bufferTimeMin: this.bufferTimeMin,
      refreshTokenElementSelector: this.refreshTokenElementSelector
    });
  }

  private get refreshTokenElementSelector(): string {
    return 'expiration-warning-are-you-still-online';
  }

  public storeToken(token: string, local: boolean): void {
    local ? localStorage.setItem('token', token) : sessionStorage.setItem('token', token);
  }

  public getToken(): string | null {
    return localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
  }

  public resetToken(token: string): void {
    const local = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
    local ? localStorage.setItem('token', token) : sessionStorage.setItem('token', token);
  }

  public tokenIncludesPermissions(token: string, permissions: Permission[]): boolean {
    const decodedToken = this.jwtHelper.decodeToken(token);
    console.log("decoded token",permissions.some((permission) => (decodedToken?.permissions as Permission[]).includes(permission)));
    return permissions.some((permission) => (decodedToken?.permissions as Permission[]).includes(permission));
  }
}
