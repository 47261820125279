import { BaseModel } from '@shared/models/base.model';
import { UserRole } from './user-role';
import { RoleBusinessId } from './role-business-id.enum';

export enum RoleAllowedCompanyType {
  CustomerChain = 'CustomerChain',
  CustomerLocation = 'CustomerLocation',
  Installer = 'Installer',
  InstallerChain = 'InstallerChain'
}

export class Role extends BaseModel {
  public _id: string;
  public name: string;
  public description: string;
  public permissions: string[];
  public default: boolean;
  public allowedCompanyTypes: RoleAllowedCompanyType[];
  public uniqueBusinessId: RoleBusinessId;
  // field not saved in DB, but used in code; it stores the many to many connection item betwwen this role and a user
  public userRole: UserRole;
}
