import { BaseMultilanguage } from './base-multilanguage.model';
import { EventType } from './event-type';

export class Event extends BaseMultilanguage {
  public event_type: EventType;

  constructor(valuesObj: any) {
    super(valuesObj);
    if (valuesObj.event_type) {
      this.event_type = new EventType(valuesObj.event_type);
    }
  }
}
