import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-label-editable',
  templateUrl: './label-editable.component.html',
  styleUrls: ['./label-editable.component.scss'],
})
export class LabelEditableComponent implements OnInit, OnChanges{
  @Input() placeholder = '';
  @Output() onSubmitEvent = new EventEmitter<{value: string}>();
  @Input() defaultValue = '';
  @Input() label: string;
  @Input() required = false;
  @Input() editable = true;
  @Input() minLength: number;
  @Input() hasPermissionToEdit: string[] = [];
  @Input() placeholderUndefined ="Non défini"
  formControl  = new FormControl();
  public editMode: boolean;

  public ngOnInit(): void {
    if (this.required){
      this.formControl.addValidators([Validators.required]);
      this.formControl.updateValueAndValidity();
    }

    if (this.minLength){
      this.formControl.addValidators([Validators.minLength(this.minLength)]);
      this.formControl.updateValueAndValidity();
    }
    this.formControl.patchValue(this.defaultValue);
  }
  get isEditMode(): boolean{
    return this.editMode;
  }


  submit(): void {
    if (this.formControl.valid ){
      this.onSubmitEvent.emit({value: this.formControl.value});
      this.switchEditMode();
    }
  }


  public clearValue(): void {
    this.formControl.patchValue( '');
  }

  public switchEditMode(): void {
    if (this.isEditMode){
      this.editMode = false;
    }else{
      this.editMode = true;
      this.formControl.patchValue(this.defaultValue);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.editable){
      if (this.editMode && !changes.editable.currentValue ){
        this.switchEditMode();
      }
    }
  }


}
