import { BaseMultilanguage } from './base-multilanguage.model';

export class EventType extends BaseMultilanguage {
  public id: string;
  public name: string;
  public color: string;
  public backgroundColor: string;

  constructor(valuesObj: any) {
    super(valuesObj);
  }
}
