import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { GraphqlService } from 'src/app/graphql/graphql.service';
import { CrudService } from 'src/app/shared/services/crud/crud.service';

import { EquipmentConfig } from './equipment-config';
import { EquipmentConfigCreate } from './equipment-config.create';
import { EquipmentConfigUpdate } from './equipment-config.update';

@Injectable({
  providedIn: 'root'
})
export class EquipmentConfigService extends CrudService<EquipmentConfig, EquipmentConfigCreate, EquipmentConfigUpdate> {
  public fragment: any;
  public modelName: string = 'EquipmentConfig';

  public constructor(private graphqlService: GraphqlService) {
    super();
    this.setFragment();
  }

  private setFragment(fragment?: any): void {
    this.fragment =
      fragment ||
      gql`
        fragment fields on ${this.modelName} {
          _id
        }
      `;
  }
}
