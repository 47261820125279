import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@users/domain/services/user.service';
import { Permission } from '@users/domain/services/user.enums';
import { LanguageService } from '@shared/services/language.service';


@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router, private languageService: LanguageService, ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const requiredPermissions = route.data.permissions as Permission[];

    if (!requiredPermissions || this.userService.loggedInUserHasPermissions(requiredPermissions)) {
      return true;
    }

    const goToUrl: string = '/' + this.languageService.currentLanguage + '/users/login';
    console.log('Permission checkCanActivate goToUrl', goToUrl);
    this.router.navigateByUrl(goToUrl);
    return false;
  }
}
