import { Injectable } from '@angular/core';
import { Role } from '@users/domain/models/role';
import { UserRole } from '@users/domain/models/user-role';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  static NotNullOrUndefined(value:any): boolean {
    return  ![null, undefined].includes(value);
  }
  public gramsToKg(grams: number | string): number {
    const gramsNumber: number = (grams as number) * 1;
    if (gramsNumber === 0) {
      return 0;
    }

    return ((gramsNumber / 1000).toFixed(3) as unknown as number) * 1;
  }

  public kgToGrams(kgs: number | string): number {
    const kgsNumber: number = (kgs as number) * 1;
    if (kgs === 0) {
      return 0;
    }

    return kgsNumber * 1000;
  }

  public gramsToTonnes(grams: number | string) : number {
    const gramsNumber: number = (grams as number) * 1;
    if (gramsNumber === 0) {
      return 0;
    }
    return ((gramsNumber / 1000000).toFixed(1) as unknown as number) * 1;
  }


  public toXDecimals(val: number | string, decimalsNumber: number): number | string {
    let valString: string = (val as string) + '';
    valString = valString.trim();
    if (valString === '') {
      return '';
    }

    const dotIndex = valString.indexOf('.');
    if (dotIndex === -1) {
      return (valString as unknown as number) * 1;
    }

    const decimalDigits: string = valString.substring(dotIndex + 1);
    const integerDigits: string = valString.substring(0, dotIndex);

    let limitedDecimalsDigits: string = decimalDigits;
    if (decimalDigits.length > decimalsNumber) {
      limitedDecimalsDigits = limitedDecimalsDigits.substring(0, decimalsNumber);
    }

    const resultWithDecimals = integerDigits + '.' + limitedDecimalsDigits;

    return (resultWithDecimals as unknown as number) * 1;
  }

  public deepCopyRole(srcRole: Role): Role {
    const destRole: Role = new Role(srcRole);
    if (srcRole.userRole) {
      destRole.userRole = new UserRole(srcRole.userRole);
    }

    return destRole;
  }
}
