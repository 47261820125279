import gql from 'graphql-tag';
import * as pluralize from 'pluralize';

export class BuildOperations {
  constructor(public fragment: any, public modelName: any) {}

  public buildQuery(one: boolean, criteria: any): any {
    return gql`
      query ${this.getQueryName(one)}${this.buildQueryParams(one, criteria)} {
        ${this.getQueryName(one)}${this.buildGQLQueryParams(one, criteria)} {
          ${this.buildQueryResponseBody(one)}
        }
      }
      ${this.fragment}
    `;
  }

  public getQueryName(one: boolean): string {
    const modelName = one ? this.modelName : pluralize.plural(this.modelName);
    return modelName.charAt(0).toLowerCase() + modelName.slice(1);
  }

  public buildSaveMutation(create: boolean): any {
    const mutationName = (create ? 'create' : 'update') + this.modelName;
    const mutationInputName: string = `${this.modelName}${create ? 'Create' : 'Update'}Input!`;
    return gql`
      mutation ${mutationName}($input: ${mutationInputName}) {
        ${mutationName}(input: $input) {
          ...fields
        }
      }
      ${this.fragment}
    `;
  }

  public buildDeleteMutation(): any {
    const mutationName = 'delete' + this.modelName;
    return gql`
    mutation ${mutationName}($id: String!) {
      ${mutationName}(id: $id)
    }
  `;
  }

  private buildQueryParams(one: boolean, criteria: any): string {
    return one ? '($id: String!)' : criteria ? `($criteria: ${this.modelName}Criteria!)` : '';
  }

  private buildGQLQueryParams(one: boolean, criteria: any): string {
    return one ? '(id: $id)' : criteria ? '(criteria: $criteria)' : '';
  }

  private buildQueryResponseBody(one: boolean): string {
    return one
      ? '...fields'
      : `
      items {
        ...fields
      }
      meta {
        total
      }`;
  }
}
